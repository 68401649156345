<template>
  <div class="chart-card">
    <bar-chart
      :graph="getChartPoints()"
      :saved="getSavedData()"
      :labels="getChartLabels()"
      class="Bar-chart"
      @goToProfile="goToProfile"
      :height="140"
    ></bar-chart>
  </div>
</template>
<script>
import BarChart from "@/components/charts/components/Bar";
import router from "@/router";

export default {
  name: "Bar",
  props: ["chartData", "saved"],
  components: {
    "bar-chart": BarChart,
  },
  methods: {
    getChartPoints() {
      if (this.chartData) {
        return this.chartData.map((item) => {
          return item.pageViewCount;
        });
      } else {
        return [];
      }
    },
    getChartLabels() {
      if (this.chartData) {
        return this.chartData.map((item) => {
          return item.name;
        });
      } else {
        return [];
      }
    },
    getSavedData() {
      let data = [];
      if (this.$route.name == "user-profile") {
        let labels = this.getChartLabels();
        labels.forEach((label) => {
          return this.saved.filter((item) => label == item.name).length > 0
            ? data.push(
                this.saved.filter((item) => label == item.name)[0].pageViewCount
              )
            : data.push(0);
        });
      }
      return data;
    },
    goToProfile(index) {
      let shortlink = this.chartData[index].shortlink;
      if (this.$route.name != "user-profile") {
        router.push({
          name: "user-profile",
          params: {
            shortlink: shortlink,
          },
        });
      }
    },
  },
};
</script>
