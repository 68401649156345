<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  name: "Bar",
  extends: Bar,
  mixins: [reactiveProp],
  props: ["graph", "options", "labels", "saved"],
  mounted() {
    this.setChart();
  },
  methods: {
    handle(point, event) {
      const item = event[0];
      this.$emit("goToProfile", item._index);
    },
    setChart() {
      let datasets = [
        {
          data: this.graph,
          label: "Views",
          borderColor: ["rgba(74, 163, 255, .3)"],
          backgroundColor: [
            "rgba(74, 163, 255, 0.8)",
            "rgba(74, 163, 255, 0.8)",
            "rgba(74, 163, 255, 0.8)",
            "rgba(74, 163, 255, 0.8)",
            "rgba(74, 163, 255, 0.8)",
            "rgba(74, 163, 255, 0.8)",
            "rgba(74, 163, 255, 0.8)",
          ],
        },
      ];

      if (this.$route.name == "user-profile") {
        this.saved.length = this.graph.length;

        datasets.push({
          data: this.saved,
          label: "Save Count",
          borderColor: ["rgba(86, 100, 210, .3)"],
          backgroundColor: [
            "rgba(86, 100, 210, 0.8)",
            "rgba(86, 100, 210, 0.8)",
            "rgba(86, 100, 210, 0.8)",
            "rgba(86, 100, 210, 0.8)",
            "rgba(86, 100, 210, 0.8)",
            "rgba(86, 100, 210, 0.8)",
            "rgba(86, 100, 210, 0.8)",
          ],
        });
      }

      this.renderChart(
        {
          labels: this.labels,
          datasets: datasets,
        },
        {
          showAllTooltips: false,
          responsive: true,
          onClick: this.handle,
          legend: {
            display: true,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  // callback: (value) => numeral(value).format("$0,0"),
                },
              },
            ],
          },
        }
      );
    },
  },
  watch: {
    graph: function () {
      this.setChart();
    },
  },
};
</script>
